import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function SignupConfirmation() {
    return (
        <div>
            <Container className="App py-5 mb-5 text-center">
            <h1>THANK YOU !</h1>
                <Row className="pt-5">
                    <Col md={4}></Col>
                    <Col md={4}>
                        <p>for creating account.</p>
                        <p>Please wait for account activation.</p>
                    </Col>
                </Row>
            </Container>
        </div>
        )
    }
export default SignupConfirmation;