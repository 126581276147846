import React, {useContext} from 'react';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
  
import { UserContext } from '../../hooks/UserContext';
import useAuth from '../../hooks/useAuth';

export default function Navigation() {
    const { user } = useContext(UserContext);
    const { logoutUser } = useAuth();
    const history = useHistory();
    const handleLogout = async () => {
        await logoutUser();
        history.push('/');
    };

  return ( user 
            ? <Navbar dark className="p-2" expand={'md'}>
                <NavbarBrand href="/">
                    <span className="text-white p-3">admin</span>
                </NavbarBrand>
                <Nav navbar className="mr-auto">
                    <NavItem>
                    <NavLink href="/wallpapers">Tapety</NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink href="/list">Edytuj/Usuń</NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink href="/add">Dodaj</NavLink>
                    </NavItem>
                <NavLink href="#" onClick={handleLogout}>
                            Wyloguj
                        </NavLink></Nav>
                </Navbar> 
            : null)
}
