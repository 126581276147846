import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWallpapers, wallpaperDelete, wallpaperUpdate } from '../store/actions/wallpaper.actions';
import { getWallpapersState } from '../store/slices/wallpaper';
import { Container, Button, Table, Input } from 'reactstrap';

function EditWallpapers() {
    const { list: wallpapersData } = useSelector(getWallpapersState);
    const dispatch = useDispatch();
    useEffect(() => dispatch(getWallpapers()), [ dispatch ] );
    
    const handleDelete = async (id) => {
        await dispatch(wallpaperDelete(id))
        dispatch(getWallpapers())
    }
    const handleChange = (_id) => async (e) => {
        await dispatch(wallpaperUpdate({_id: _id, [e.target.name]: e.target.value}));
        dispatch(getWallpapers())
    }

    return (
        <Container className="App pb-5 mb-5">
            <div className="text-center p-2 App-header mb-4">
                <h1 className="h1">
                <span className="">ASTRO</span>
                </h1>
                <span className="fw-light text-start subtitle">TAPETA</span>
            </div>
            <Table dark className="text-start">
                <thead>
                    <tr>
                        <th width="20%">Miesiąc</th>
                        <th width="10%">Desktop</th>
                        <th width="10%">Mobile</th>
                        <th width="10%">Pozycja/Waga</th>
                        <th width="40%">Akcje</th>
                    </tr>
                </thead>
                <tbody>
                    {wallpapersData && wallpapersData.map((wallpaper, i) => 
                    <tr key={i}>
                        <td>{wallpaper.month}</td>
                        <td><img alt="" src={`${wallpaper.thumbnailImg.url}`} target="_blank" width="200px" href={`${wallpaper.desktopImg.url}`}/></td>
                        <td><img alt="" src={`${wallpaper.mobileImg.url}`} target="_blank" height="120px" href={`${wallpaper.mobileImg.url}`}/></td>
                        <td>
                            <Input className="d-inline" type="number" name="weight" onChange={handleChange(wallpaper._id, wallpaper.weight)} value={wallpaper.weight} width="1"/>
                        </td>
                        <td>
                            <Button href={`edit/${wallpaper._id}`} className="me-2" color="warning">Edytuj</Button>
                            <Button onClick={() => handleDelete(wallpaper._id)} type={"button"} className="me-2" color="danger">Usuń</Button>
                        </td>
                    </tr>)}
                </tbody>
            </Table>
        </Container>
        )
    }
export default EditWallpapers;