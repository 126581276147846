import { createSlice } from '@reduxjs/toolkit';
import { getWallpapers, getWallpaper } from '../actions/wallpaper.actions';

const initialState = {
  error: null,
  status: 'idle',
  list: null,
  current: null
};

const wallpaperSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getWallpapers.pending]: (state) => {
      state.error = null;
      state.list = null;
      state.status = 'pending';
    },
    [getWallpapers.fulfilled]: (state, action) => {
      state.error = null;
      state.list = action.payload;
      state.status = 'fulfilled';
    },
    [getWallpapers.rejected]: (state, action) => {
      state.error = null;
      state.list = null;
      state.status = 'rejected';
    },
    [getWallpaper.pending]: (state) => {
      state.error = null;
      state.current = null;
      state.status = 'pending';
    },
    [getWallpaper.fulfilled]: (state, action) => {
      state.error = null;
      state.current = action.payload;
      state.status = 'fulfilled';
    },
    [getWallpaper.rejected]: (state, action) => {
      state.error = null;
      state.current = null;
      state.status = 'rejected';
    }
  },
});
  


export const getWallpapersState = state => state.wallpaper;

export default wallpaperSlice.reducer;
