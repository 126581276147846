import React, { useState } from 'react';
import { Form, Input, Label, FormGroup, Row, Col, Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { wallpaperAdd, wallpaperImageUpload } from '../store/actions/wallpaper.actions';
import { Spinner } from 'reactstrap';
import FormData from 'form-data';
import Compressor from 'compressorjs';

function AddWallpaper() {
    const [ wallpaper, setWallpaper ] = useState({
        month: '',
        imgAuthor: ''
    })
    const [ desktopWallpaper, setDesktopWallpaper ] = useState(null);
    const [ mobileWallpaper, setMobileWallpaper ] = useState(null);
    const [ desktopIsLoading, setDesktopIsLoading ] = useState(false)
    const [ mobileIsLoading, setMobileIsLoading ] = useState(false)
    const [ thumbnailWallpaper, setThumbnailWallpaper ] = useState(null);

    const dispatch = useDispatch();
    const history = useHistory();
    
  
    const handleAdd = async () => {
      await dispatch(wallpaperAdd({...wallpaper, desktopImg: desktopWallpaper, mobileImg: mobileWallpaper, thumbnailImg: thumbnailWallpaper}));
      history.goBack();
    }

    const handleChange = (e) => {
        setWallpaper({...wallpaper, [e.target.name]: e.target.value});
    }

    const onAddDesktopImage = async e => {
        const files = Array.from(e.target.files)
        setDesktopIsLoading(true)
    
        const formData = new FormData()
    
        formData.append('imageFiles', files[0])
        const { payload } = await dispatch(wallpaperImageUpload(formData))
        setDesktopIsLoading(false)
        setDesktopWallpaper(payload);
        new Compressor(files[0], {      
            quality: 1,
            width: 1320,
            success: async (compressedResult) => {
                const smallFile = compressedResult;
                const fileExt = smallFile.name.split('.').pop();
                smallFile.name = `small.${fileExt}`
                const fd = new FormData();
                fd.append('smallFile', compressedResult, compressedResult.name)
                const { payload } = await dispatch(wallpaperImageUpload(fd))
                setThumbnailWallpaper(payload);
            },
        });
    }

    const onAddMobileImage = async e => {
        const files = Array.from(e.target.files)
        setMobileIsLoading(true)
    
        const formData = new FormData()
    
        formData.append('wp', files[0])
        const { payload } = await dispatch(wallpaperImageUpload(formData))
        setMobileIsLoading(false)
        setMobileWallpaper(payload);
    }
    
    return (
            <div className="py-5 mb-5 text-center">
                <h1 >Dodaj tapetę</h1>
                <Row className="pt-5 text-start">
                    <Col md={3}></Col>
                    <Col md={6}>
                        <Form encType="multipart/form-data">
                            <FormGroup className="mb-4">
                                <Label className="d-block" for="month">Miesiąc</Label>
                                <Input type="text" name="month" onChange={handleChange} value={wallpaper.month}/>
                            </FormGroup>
                            <FormGroup className="mb-4">
                                <Label className="d-block" for="desktop">Desktop</Label>
                                <Input type="file" name="desktop" onChange={onAddDesktopImage}/>
                                { desktopIsLoading && <Spinner color="secondary" />}
                            </FormGroup>
                            {thumbnailWallpaper && <img src={thumbnailWallpaper.url} alt="" width="100%"/>}
                            <FormGroup className="mb-4">
                                <Label className="d-block" for="mobile">Mobile</Label>
                                <Input type="file" name="mobile" onChange={onAddMobileImage}/>
                                { mobileIsLoading && <Spinner color="secondary" />}
                            </FormGroup>
                            {mobileWallpaper && <img src={mobileWallpaper.url} alt="" width="100%"/>}
                            <FormGroup className="mb-4">
                                <Label className="d-block" for="imgAuthor">Autor zdjęcia</Label>
                                <Input type="text" name="imgAuthor" onChange={handleChange} value={wallpaper.imgAuthor}/>
                            </FormGroup>
                            <FormGroup className="mb-4">
                                <Label className="d-block" for="weight">Pozycja/waga</Label>
                                <Input type="number" name="weight" onChange={handleChange} value={wallpaper.weight}/>
                            </FormGroup>
                            <FormGroup className="mb-4">
                                <Label className="d-block" for="description">Opis</Label>
                                <Input type="textarea" name="description" onChange={handleChange} value={wallpaper?.description}/>
                            </FormGroup>
                        </Form>
                        <Button color="success" onClick={handleAdd} className={"mt-4"}>Add</Button>
                    </Col>
                </Row>
            </div>
        )
    }
export default AddWallpaper;