import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from './hooks/UserContext';
import { Spinner } from 'reactstrap';

export default function PrivateRoute(props) {
  const { user, isLoading } = useContext(UserContext);
  const { component: Component, ...rest } = props;

  if (isLoading) {
    return <div className="text-center"><Spinner color="danger" children={''} /></div>;
  }
  if (!!user) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
  return <Redirect to="/login" />;
}
