import './App.css';
import Routes from './routes';
import { UserContext } from './hooks/UserContext';
import useFindUser from './hooks/useFindUser';
import Navigation from './components/navigation/Navigation.component'
import { Row, Col, Container} from 'reactstrap';
import { faDiscord, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function App() {
  const { user, setUser, isLoading } = useFindUser();

  return (
      <UserContext.Provider value={{ user, setUser, isLoading }}>
        <Navigation/>
        <Routes/>
        <Container fluid className="app-footer px-3 mt-3 p-2 float-left">
          <Row>
              <Col xs="6" sm="6">
              <a href="https://www.facebook.com/astrolifepl" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebook}/> <span className="app-footer-link-text">Facebook</span></a>
              <a href="https://www.youtube.com/channel/UCxZGztWF3HTYTs3iQlRkWrg" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faYoutube}/> <span className="app-footer-link-text">Youtube</span></a>
              <a href="https://discord.gg/gCQ9RV5c" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faDiscord}/> <span className="app-footer-link-text">Discord</span></a>
              </Col>
              <Col xs="6" sm="6" className="text-end">
              <a href="https://patronite.pl/astrolife" className="text-end" rel="noreferrer"target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt}/> Patronite</a>
              </Col>
          </Row>
        </Container>
      </UserContext.Provider>
  );
}

export default App;
