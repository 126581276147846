import React, { useState, useEffect } from 'react';
import { Container, Form, Input, Label, FormGroup, Row, Col, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { wallpaperUpdate, wallpaperImageUpload, getWallpaper } from '../store/actions/wallpaper.actions';
import { getWallpapersState } from '../store/slices/wallpaper';
import { Spinner } from 'reactstrap';
import FormData from 'form-data';

function EditWallpaper(props) {
    const { current: wallpaper } = useSelector(getWallpapersState);
    const [ modifiedWallpaper, setModifiedWallpaper ] = useState(null);
    const [ desktopWallpaper, setDesktopWallpaper ] = useState(null);
    const [ mobileWallpaper, setMobileWallpaper ] = useState(null);
    const [ desktopIsLoading, setDesktopIsLoading ] = useState(false)
    const [ mobileIsLoading, setMobileIsLoading ] = useState(false)
    
    const dispatch = useDispatch();
    const history = useHistory();
    
    useEffect(() => {
        if (!wallpaper) {
            dispatch(getWallpaper(props.match.params.id))
        } else {
            setModifiedWallpaper(wallpaper)
            setDesktopWallpaper(wallpaper.desktopImg);
            setMobileWallpaper(wallpaper.mobileImg);
        }
    }, [ dispatch, wallpaper, props ] );
  
    const handleUpdate = async () => {
      await dispatch(wallpaperUpdate({...modifiedWallpaper, desktopImg: desktopWallpaper, mobileImg: mobileWallpaper}));
      history.push('/list');
    }

    const handleChange = (e) => {
        setModifiedWallpaper({...wallpaper, [e.target.name]: e.target.value});
    }

    const onAddDesktopImage = async e => {
        const files = Array.from(e.target.files)
        setDesktopIsLoading(true)
    
        const formData = new FormData()
    
        formData.append('imageFiles', files[0])
        const { payload } = await dispatch(wallpaperImageUpload(formData))
        setDesktopIsLoading(false)
        setDesktopWallpaper(payload);
    }

    const onAddMobileImage = async e => {
        const files = Array.from(e.target.files)
        setMobileIsLoading(true)
    
        const formData = new FormData()
    
        formData.append('wp', files[0])
        const { payload } = await dispatch(wallpaperImageUpload(formData))
        setMobileIsLoading(false)
        setMobileWallpaper(payload);
    }
    
    return (
        <div>
            <Container className="App py-5 mb-5 text-center">
                <h1 >Edytuj tapetę</h1>
                <Row className="pt-5 text-start">
                    <Col md={3}></Col>
                    <Col md={6}>
                            {modifiedWallpaper &&
                        <Form encType="multipart/form-data">
                            <FormGroup className="mb-4">
                                <Label className="d-block" for="month">Miesiąc</Label>
                                <Input type="text" name="month" onChange={handleChange} value={modifiedWallpaper?.month}/>
                            </FormGroup>
                            <FormGroup className="mb-4">
                                <Label className="d-block" for="desktop">Desktop</Label>
                                <Input type="file" name="desktop" onChange={onAddDesktopImage}/>
                                { desktopIsLoading && <Spinner color="secondary" />}
                            </FormGroup>
                            {desktopWallpaper && <img src={desktopWallpaper} alt="" width="100%"/>}
                            <FormGroup className="mb-4">
                                <Label className="d-block" for="mobile">Mobile</Label>
                                <Input type="file" name="mobile" onChange={onAddMobileImage}/>
                                { mobileIsLoading && <Spinner color="secondary" />}
                            </FormGroup>
                            {mobileWallpaper && <img src={mobileWallpaper} alt="" width="100%"/>}
                            <FormGroup className="mb-4">
                                <Label className="d-block" for="imgAuthor">Autor zdjęcia</Label>
                                <Input type="text" name="imgAuthor" onChange={handleChange} value={modifiedWallpaper?.imgAuthor}/>
                            </FormGroup>
                            <FormGroup className="mb-4">
                                <Label className="d-block" for="weight">Pozycja/waga</Label>
                                <Input type="number" name="weight" onChange={handleChange} value={modifiedWallpaper?.weight}/>
                            </FormGroup>
                            <FormGroup className="mb-4">
                                <Label className="d-block" for="description">Opis</Label>
                                <Input type="textarea" name="description" onChange={handleChange} value={modifiedWallpaper?.description}/>
                            </FormGroup>
                        </Form>
                            }
                        <Button color="success" onClick={handleUpdate} className={"mt-4"}>Update</Button>
                    </Col>
                </Row>
            </Container>
        </div>
        )
    }
export default EditWallpaper;