import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React from 'react';
import Home from './views/Home';
import Login from './views/Login';
import Signup from './views/Signup';
import { Container } from 'reactstrap';
import SignupConfirmation from './views/SignupConfirmation';
import PrivateRoute from './PrivateRoute'
import AddWallpaper from './views/AddWallpaper';
import EditWallpapers from './views/EditWallpapers';
import EditWallpaper from './views/EditWallpaper';

export default function Routes() {
  return (
    <Container className={'p--md-5 mt-2'}>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/wallpapers" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Signup} />
          <PrivateRoute exact path="/add" component={AddWallpaper} />
          <PrivateRoute exact path="/list" component={EditWallpapers} />
          <PrivateRoute exact path="/edit/:id" component={EditWallpaper} />
          <Route exact path="/register/confirmation" component={SignupConfirmation} />
        </Switch>
      </Router>
    </Container>
  );
}