import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWallpapers } from '../store/actions/wallpaper.actions';
import { getWallpapersState } from '../store/slices/wallpaper';
import { Container, Row, Col, Button } from 'reactstrap';

function Home() {
    const { list: wallpapersData } = useSelector(getWallpapersState);
    const dispatch = useDispatch();
    useEffect(() => dispatch(getWallpapers()), [ dispatch ] );
    return (
        <Container className="App pb-5 mb-5">
            <div className="text-center p-2 App-header mb-4">
                <h1 className="h1">
                <span className="">ASTRO</span>
                </h1>
                <span className="fw-light text-start subtitle">TAPETA</span>
            </div>
            {wallpapersData && wallpapersData.map((wallpaper, i) => 
            <Row className={"p-2"} key={i}>
            <Col sm={12}>
                <h2 className="month-name">{wallpaper.month}</h2>
                <a href={`${wallpaper.desktopImg && wallpaper.desktopImg.url}`}>
                <img alt="" src={`${wallpaper.thumbnailImg && wallpaper.thumbnailImg.url}`} width="100%" href={`${wallpaper.desktopImg && wallpaper.desktopImg.url}`}/>
                </a>
                <div className={"text-start actions"}>
                    <Button color={"white"}><a href={`${wallpaper.desktopImg && wallpaper.desktopImg.url}`}>Wersja desktopowa</a></Button>{' '}{wallpaper.mobileImg && wallpaper.mobileImg.url &&<Button color={"white"}> <a href={`${wallpaper.mobileImg.url}`}>Wersja na telefon</a></Button>}
                </div>
            </Col>
            </Row>
            )}
        </Container>
        )
    }
export default Home;