import React, { useState } from 'react';
import { Container, Form, Input, Label, FormGroup, Row, Col, Button } from 'reactstrap';
import useAuth from '../hooks/useAuth';
import { useHistory } from 'react-router-dom';

function Login() {
    const [ user, setUser ] = useState({ email: '', password: '' });
    const [ error, setError ] = useState(null);
    const { loginUser } = useAuth();
    const history = useHistory();

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        await loginUser(user).then(() => history.push('/wallpapers')).catch(err => setError(err.message));
    };

    const handleChangeInput = e => {
        setUser({ ...user, [e.target.name]: e.target.value }); 
    };

    return (
        <div>
            <Container className="App py-5 mb-5 text-center">
            <h1>Login</h1>
                <Row className="pt-5">
                    <Col md={4}></Col>
                    <Col md={4}>
                        <Form >
                            <FormGroup className="text-start" onChange={handleChangeInput}>
                                <Label>E-mail</Label>
                                <Input type="text" name="email"/>
                            </FormGroup>
                            <FormGroup className="text-start" onChange={handleChangeInput}>
                                <Label>Password</Label>
                                <Input type="password" name="password"/>
                            </FormGroup>
                        </Form>
                        <Button color="success" className="my-4" onClick={handleSubmitForm}>Login</Button>
                        {error && <p className="text-danger"><small>{error}</small></p>}
                    </Col>
                </Row>
            </Container>
        </div>
        )
    }
export default Login;