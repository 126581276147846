import { createSlice } from '@reduxjs/toolkit';

import { userLogin, userCreate } from '../actions/user.actions';

const initialState = {
  error: null,
  status: 'idle',
  data: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [userLogin.pending]: (state) => {
      state.error = null;
      state.data = null;
      state.status = 'pending';
    },
    [userLogin.fulfilled]: (state, action) => {
      state.error = null;
      state.data = {
        ...state.data,
        user: action.payload,
      };
      state.status = 'fulfilled';
    },
    [userLogin.rejected]: (state, action) => {
      state.error = null;
      state.data = null;
      state.status = 'rejected';
    },
    [userCreate.pending]: (state) => {
      state.error = null;
      state.data = null;
      state.status = 'pending';
    },
    [userCreate.fulfilled]: (state, action) => {
      state.error = null;
      state.data = {
        ...state.data,
        user: action.payload,
      };
      state.status = 'fulfilled';
    },
    [userCreate.rejected]: (state, action) => {
      state.error = null;
      state.data = null;
      state.status = 'rejected';
    },
  },
});

export default userSlice.reducer;
