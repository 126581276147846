import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from './UserContext';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const USER_HOMEPAGE = '/user/dashboard';
const HOMEPAGE = '/';

export default function useAuth() {
  let history = useHistory();
  const { setUser } = useContext(UserContext);
  const [error, setError] = useState(null);
  //set user in context and push them home
  const setUserContext = async () => {
    return await axios
      .get(`${REACT_APP_API_URL}/user/auth`, {
        withCredentials: true,
        credentials: 'include',
      })
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        setError(err.response.data);
      });
  };
  //register user
  const registerUser = async (data) => {
    const { email, password, passwordConfirm } = data;
    return axios
      .post(`${REACT_APP_API_URL}/user/create`, {
        email,
        password,
        passwordConfirm,
      })
      .then(async () => {
        await setUserContext();
      })
      .catch((err) => {
        setError(err.response.data);
        throw new Error(err.response.data)
      });
  };
  //login user
  const loginUser = async (data) => {
    const { email, password } = data;
    return axios
      .post(`${REACT_APP_API_URL}/user/login`, {
        email,
        password,
      }, {
        withCredentials: true,
        credentials: 'include',
      })
      .then(async (user) => {
        await setUserContext();
        history.push(`${USER_HOMEPAGE}`);
      })
      .catch((err) => {
        setError(err.response.data);
        throw new Error(err.response.data)
      });
  };
    //logout user
    const logoutUser = async () => {
      return axios
        .post(`${REACT_APP_API_URL}/user/logout`, {}, {
          withCredentials: true,
          credentials: 'include',
        })
        .then(async (user) => {
          setUser(null);
          history.push(`${HOMEPAGE}`);
        })
        .catch((err) => {
          setError(err.response.data);
          throw new Error(err.response.data)
        });
    };

  return {
    error,
    registerUser,
    loginUser,
    logoutUser
  };
}
