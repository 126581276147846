import React, { useState } from 'react';
import { Container, Form, Input, Label, FormGroup, Row, Col, Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userCreate } from '../store/actions/user.actions';

function Signup() {
    const [user, setUser ] = useState({email:'', password: ''});
    const [ emailError, setEmailError ] = useState(null);
    const [ passwordError, setPasswordError ] = useState(null);
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ confirmPasswordError, setConfirmPasswordError ] = useState(null);
    const [error, setError] = useState(null)
    const dispatch = useDispatch();
    const history = useHistory();
    
  
    const handleCreate = async () => {
      const isValid = validate();
      if (!isValid) {
        setError('Passwords not same');
        return;
      }
      setError(null);
      await dispatch(userCreate(user));
      history.push('/register/confirmation');
    }
  
    const validate = () => user.password === confirmPassword
  
    const handleChange = (e) => {
        if (e.target.name === 'password') {
            checkPassword(e)
        }
        if(e.target.name === 'email') {
            checkEmail(e)
        }
        setUser({...user, [e.target.name]: e.target.value});
    }
  
    const handleConfirmPassword = (e) => {
        setConfirmPasswordError(null);
        if (e.target.value !== user.password) {
            setConfirmPasswordError('Passwords not match');
        } 
        setConfirmPassword(e.target.value);
    }

    const checkEmail = (e) => {
        setEmailError(null);
        //eslint-disable-next-line
        if (!new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$").test(e.target.value)) {
            setEmailError('email address not valid');
        }
    }

    const checkPassword = (e) => {
        //eslint-disable-next-line
        const passwordMatrix = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        const password = [];
        const LOWERCASE = 'Password must contain at least 1 lowercase alphabetical character';
        const UPPERCASE = 'Password must contain at least 1 uppercase alphabetical character';
        const NUMERIC = 'Password must contain at least 1 numeric character';
        const SPECIAL = 'Password must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict';
        const CHARS = 'Password must be eight characters or longer';
        setPasswordError(null);
        if (!new RegExp("^(?=.*[a-z])").test(e.target.value)) {
            password.push(LOWERCASE)
        }
        if (!new RegExp("^(?=.*[A-Z])").test(e.target.value)) {
            password.push(UPPERCASE)
        }
        if (!new RegExp("^(?=.*[0-9])").test(e.target.value)) {
            password.push(NUMERIC)
        }
        if (!new RegExp("^(?=.*[!@#$%^&*])").test(e.target.value)) {
            password.push(SPECIAL)
        }
        if (!new RegExp("^(?=.{8,})").test(e.target.value)) {
            password.push(CHARS)
        }
        const isValid = passwordMatrix.test(e.target.value)
        if (!isValid) {setPasswordError(password)}
        return isValid
    }

    return (
        <div>
            <Container className="App py-5 mb-5 text-center">
                <h1 >Create account</h1>
                <Row className="pt-5">
                    <Col md={3}></Col>
                    <Col md={6}>
                        <Form>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input type="text" name="email" onChange={handleChange} value={user.email}/>
                            </FormGroup>
                            {emailError && <p><small className="text-danger">{emailError}</small></p>}
                            <FormGroup>
                                <Label for="password">Password</Label>
                                <Input type="password" name="password" onChange={handleChange} value={user.password}/>
                            </FormGroup>
                            {passwordError && <ul className="text-start">{passwordError.map((error) => (<li className="text-danger"><small>{error}</small></li>))}</ul>}
                            <FormGroup>
                                <Label for="confirm-password">Confirm Password</Label>
                                <Input type="password" name="confirm-password" onChange={handleConfirmPassword} value={confirmPassword}/>
                            </FormGroup>
                            {confirmPasswordError && <p><small className="text-danger">{confirmPasswordError}</small></p>}
                        </Form>
                        {error && <p><small className="text-danger">{error}</small></p>}
                        <Button color="success" onClick={handleCreate} className={"mt-4"}>Create User</Button>
                    </Col>
                </Row>
            </Container>
        </div>
        )
    }
export default Signup;