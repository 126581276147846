import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_URL;

const userLogin = createAsyncThunk(
  'userLogin',
  async (user, thunkAPI) => {
    try {
      const response = await Axios.post(
        `${apiBaseUrl}/user/login`,
        user,
        {
          withCredentials: true,
          credentials: 'include'
        }
      );
      if (!response.status === '200') {
        const error = await response.json();
        return thunkAPI.rejectWithValue({ error: error.message });
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message ? error.message : error,
      });
    }
  },
);

const userCreate = createAsyncThunk(
  'userCreate',
  async (user, thunkAPI) => {
    try {
      const response = await Axios.post(
        `${apiBaseUrl}/user/create`,
        user
      );
      if (!response.status === '200') {
        const error = await response.json();
        return thunkAPI.rejectWithValue({ error: error.message });
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message ? error.message : error,
      });
    }
  },
);

export { userLogin, userCreate };