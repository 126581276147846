import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_URL;

const getWallpapers = createAsyncThunk(
  'getWallpapers',
  async (thunkAPI) => {
    try {
      const response = await Axios.get(
        `${apiBaseUrl}/wallpaper/list`
      );
      if (!response.status === '201') {
        const error = await response.json();
        return thunkAPI.rejectWithValue({ error: error.message });
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message ? error.message : error,
      });
    }
  },
);

const getWallpaper = createAsyncThunk(
  'getWallpaper',
  async (id, thunkAPI) => {
    try {
      const response = await Axios.get(
        `${apiBaseUrl}/wallpaper/${id}`
      );
      if (!response.status === '201') {
        const error = await response.json();
        return thunkAPI.rejectWithValue({ error: error.message });
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message ? error.message : error,
      });
    }
  },
);

const wallpaperAdd = createAsyncThunk(
  'wallpaperAdd',
  async (wallpaper, thunkAPI) => {
    try {
      const response = await Axios.post(
        `${apiBaseUrl}/wallpaper/add`,
        wallpaper,
        {
          withCredentials: true,
          credentials: 'include',
        }
      );
      if (!response.status === '200') {
        const error = await response.json();
        return thunkAPI.rejectWithValue({ error: error.message });
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message ? error.message : error,
      });
    }
  },
);

const wallpaperUpdate = createAsyncThunk(
  'wallpaperUpdate',
  async (wallpaper, thunkAPI) => {
    try {
      const response = await Axios.put(
        `${apiBaseUrl}/wallpaper/${wallpaper._id}`,
        wallpaper,
        {
          withCredentials: true,
          credentials: 'include',
        }
      );
      if (!response.status === '200') {
        const error = await response.json();
        return thunkAPI.rejectWithValue({ error: error.message });
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message ? error.message : error,
      });
    }
  },
);

const wallpaperDelete = createAsyncThunk(
  'wallpaperDelete',
  async (id, thunkAPI) => {
    try {
      const response = await Axios.delete(
        `${apiBaseUrl}/wallpaper/${id}`,
        {
          withCredentials: true,
          credentials: 'include',
        }
      );
      if (!response.status === '201') {
        const error = await response.json();
        return thunkAPI.rejectWithValue({ error: error.message });
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message ? error.message : error,
      });
    }
  },
);

const wallpaperImageUpload = createAsyncThunk(
  'wallpaperImageUpload',
  async (formData, thunkAPI) => {
    try {
      const response = await Axios.post(
        `${apiBaseUrl}/wallpaper/upload`,
        formData,
        {
          withCredentials: true,
          credentials: 'include',
        }
      );
      if (!response.status === '200') {
        const error = await response.json();
        return thunkAPI.rejectWithValue({ error: error.message });
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message ? error.message : error,
      });
    }
  },
);

export { wallpaperAdd, wallpaperImageUpload, getWallpapers, getWallpaper, wallpaperUpdate, wallpaperDelete };